<template>
  <sidenav
    :custom_class="this.$store.state.mcolor"
    :class="[
      this.$store.state.isTransparent,
      this.$store.state.isRTL ? 'fixed-end' : 'fixed-start',
    ]"
    v-if="this.$store.state.showSidenav"
  />
  <main
    class="main-content position-relative max-height-vh-100 h-100 border-radius-lg"
    :style="this.$store.state.isRTL ? 'overflow-x: hidden' : ''"
  >
    <!-- Nav -->
    <navbar
      :class="[navClasses]"
      :textWhite="this.$store.state.isAbsolute ? 'text-white opacity-8' : ''"
      :minNav="navbarMinimize"
      v-if="this.$store.state.showNavbar"
    />
    <router-view @show-modal="listening" />
    <app-footer v-show="this.$store.state.showFooter" />

    <ModalAlertPassword :isOpen="openModal" @close-modal="closeModal" />
  </main>
</template>

<script>
import Sidenav from './examples/Sidenav';
import Navbar from '@/examples/Navbars/Navbar.vue';
import AppFooter from '@/examples/Footer.vue';
import { mapMutations } from 'vuex';
import ModalAlertPassword from './views/components/ModalAlertPassword.vue';
import TokenService from '@/services/token.service';

export default {
  name: 'App',
  components: {
    Sidenav,
    Navbar,
    AppFooter,
    ModalAlertPassword,
  },
  data() {
    return {
      openModal: false,
    };
  },
  methods: {
    ...mapMutations([
      'toggleConfigurator',
      'navbarMinimize',
      'showAlertPassword',
    ]),
    closeModal() {
      this.openModal = false;
    },
    listening(value) {
      const user = TokenService.getUser();

      if (value && user?.showAlertPassword) {
        this.openModal = true;
        this.showAlertPassword(false);
        user.showAlertPassword = false;
        TokenService.setUser(user);
      }
    },
  },
  computed: {
    navClasses() {
      return {
        'position-sticky blur shadow-blur mt-4 left-auto top-1 z-index-sticky': this
          .$store.state.isNavFixed,
        'position-absolute px-4 mx-0 w-100 z-index-2': this.$store.state
          .isAbsolute,
        'px-0 mx-4 mt-4': !this.$store.state.isAbsolute,
      };
    },
  },
  beforeMount() {
    this.$store.state.isTransparent = 'bg-transparent';
  },
};
</script>
