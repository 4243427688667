<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <!-- Filters Start -->
      <div class="col-12 mb-4">
        <div class="card">
          <div class="card-body p-3">
            <div class="row justify-content-center">
              <div class="col-12 col-md-3">
                <label for="dniSearch" class="form-label"
                  >No de Identificación</label
                >
                <input
                  type="text"
                  class="form-control"
                  id="dniSearch"
                  v-model="dniSearch"
                  @input="onlyNumberReg"
                  @change="onlyNumberReg"
                />
                <div v-if="vf.dniSearch.$error" class="invalid-feedback">
                  {{ vf.dniSearch.$errors[0].$message }}
                </div>
              </div>

              <div class="col-12 col-md-3">
                <div class="form-group">
                  <label for="stateSearch">Estados</label>
                  <select
                    class="form-control"
                    id="stateSearch"
                    v-model="stateSearch"
                  >
                    <option v-if="isAdmin" value="-1">Todos</option>
                    <option value="1">Activo</option>
                    <option value="18">Realizado</option>
                    <option value="19">Caducado</option>
                    <option v-if="isAdmin" value="17">Eliminado</option>
                  </select>
                </div>
              </div>

              <div class="col-12 col-md-3">
                <button
                  type="button"
                  class="btn btn-success mb-0 w-100"
                  style="margin-top: 30px"
                  @click="search"
                  :disabled="loading"
                >
                  <i v-if="loading" class="fas fa-circle-notch fa-spin"></i>
                  <i v-else class="fas fa-filter"></i> Filtrar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Filters End -->
    </div>
    <div class="row">
      <div class="col-12">
        <table-users-assisted
          :user-list="listUsers"
          :loading="loading"
          :pages="pages"
          :perPages="perPages"
          @updateList="updateList"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TableUsersAssisted from './components/TableUsersAssisted.vue';
import { onlyNumberReg } from '@/utils/functions';
import { computed, onMounted, ref, watch } from 'vue';
import axiosInstance from '@/services/api';
import Swal from 'sweetalert2';
import { useStore } from 'vuex';
import { maxLength, helpers } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import useEventsBus from '@/helpers/eventBus';

export default {
  components: {
    TableUsersAssisted,
  },

  setup(props, { emit }) {
    const store = useStore();

    const { bus } = useEventsBus();

    const { getUser } = store.getters;

    const dniSearch = ref(null);
    const stateSearch = ref(1);

    const loading = ref(true);
    const listUsers = ref([]);
    const pages = ref(1);
    const perPages = ref(10);

    const isAdmin = computed(() => getUser.idRol == 1);

    const rules = computed(() => ({
      dniSearch: {
        maxLength: helpers.withMessage('Máximo 50 caracteres', maxLength(50)),
      },
    }));

    const vf = useVuelidate(rules, { dniSearch });

    const getUserList = (dniSearch) => {
      axiosInstance
        .get(
          `/getUsersAssisted/dni/${dniSearch || -1}/state/${
            stateSearch.value
          }/rolUser/${getUser.idRol}/user/${getUser.userId}`
        )
        .then(({ data }) => {
          listUsers.value = data.body;

          pages.value = Math.ceil(listUsers.value.length / perPages.value);
        })
        .catch((err) => {
          listUsers.value = [];

          if (err.response.status === 401) return;

          Swal.fire({
            title: 'Inconveniente en el sistema, intente más tarde',
            icon: 'warning',
            confirmButtonText: 'Aceptar',
          });
        })
        .finally(() => (loading.value = false));
    };

    getUserList(-1);

    const updateList = async () => {
      loading.value = true;

      getUserList(dniSearch.value);
    };

    const search = async () => {
      vf.value.$touch();

      if (vf.value.dniSearch.$invalid) return false;

      loading.value = true;

      getUserList(dniSearch.value);
    };

    watch(
      () => bus.value.get('create-userAssisted-success'),
      () => {
        getUserList(dniSearch.value);
      }
    );

    onMounted(() => {
      if (getUser.daysExpiration <= 5 && getUser.showAlertPassword)
        emit('show-modal', true);
    });

    return {
      loading,
      listUsers,
      pages,
      perPages,
      onlyNumberReg,
      search,
      dniSearch,
      stateSearch,
      getUser,
      vf,
      updateList,
      isAdmin,
    };
  },
};
</script>
