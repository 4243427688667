<template>
  <footer class="py-3 footer">
    <div class="container-fluid">
      <div class="row align-items-center justify-content-lg-between">
        <div class="mb-4 col-lg-6 mb-lg-0">
          <div
            class="text-sm text-center copyright text-muted"
            :class="this.$store.state.isRTL ? 'text-lg-end' : 'text-lg-start'"
          >
            &copy; {{ new Date().getFullYear() }}, desarrollado por
            <a href="javascript:void(0)" class="font-weight-bold">
              Data 15-22 S.A.S. v1.0.9
            </a>
          </div>
        </div>
        <div class="col-lg-6"></div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'app-footer',
};
</script>
