<template>
  <div
    class="w-auto h-auto collapse navbar-collapse max-height-vh-100 h-100"
    id="sidenav-collapse-main"
  >
    <ul class="navbar-nav">
      <li
        v-for="(item, index) of $store.getters.getMenus"
        :key="index"
        class="nav-item"
      >
        <sidenav-collapse
          :navText="item.navText"
          :to="{ name: item.nameRouter }"
        >
          <template #icon>
            <span class="fa-1x color-link" :class="item.icon"></span>
          </template>
        </sidenav-collapse>
      </li>
    </ul>
  </div>
</template>

<script>
import SidenavCollapse from './SidenavCollapse.vue';
import TokenService from '@/services/token.service';

export default {
  name: 'SidenavList',
  props: {
    cardBg: String,
  },
  data() {
    return {
      title: 'Soft UI Dashboard PRO',
      controls: 'dashboardsExamples',
      isActive: 'active',
    };
  },
  components: {
    SidenavCollapse,
  },
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split('/');
      return routeArr[1];
    },
  },
  beforeCreate() {
    if (TokenService.getUser()?.menus.length > 0)
      this.$store.dispatch('setMenus', TokenService.getUser().menus);

    if (TokenService.getUser()?.idRol) {
      this.$store.dispatch('setInitState', {
        userId: TokenService.getUser().userId,
        idRol: TokenService.getUser().idRol,
        idEntidad: TokenService.getUser().idEntidad,
        fullname: TokenService.getUser().fullname,
        nameRol: TokenService.getUser().nameRol,
        daysExpiration: TokenService.getUser().daysExpiration,
        showAlertPassword: TokenService.getUser().showAlertPassword,
      });
    }
  },
};
</script>

<style scoped>
.color-link {
  color: #3a416f;
}

.active > div > .color-link {
  color: #ffff !important;
}
</style>
