<template>
  <div class="modal" tabindex="-1" id="myModalPasswordAlert">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title"><b>Notificación de Caducidad</b></h5>
        </div>
        <div class="modal-body">
          <p class="h5">
            Queremos informarte que la vigencia de tu contraseña está próxima a
            expirar. Para garantizar la seguridad de tu cuenta, te recomendamos
            realizar el cambio a la brevedad.
            <br /><br />
            Puedes actualizar tu contraseña haciendo clic
            <a
              class="link-info"
              :href="$router.resolve({ name: 'reset-password' }).href"
              target="_blank"
              rel="noopener noreferrer"
            >
              aquí.
            </a>
          </p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" @click="closedModal">
            cerrar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watchEffect, toRefs, onMounted } from 'vue';
import { Modal } from 'bootstrap';

export default {
  name: 'modal-alert-password',

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const { isOpen } = toRefs(props);

    const modal = ref(null);

    const closedModal = () => {
      emit('close-modal', false);
    };

    watchEffect(() => {
      if (modal.value != null) {
        if (isOpen.value) modal.value.show();
        if (!isOpen.value) modal.value.hide();
      }
    });

    onMounted(() => {
      modal.value = new Modal(
        document.getElementById('myModalPasswordAlert'),
        {}
      );
    });

    return {
      closedModal,
    };
  },
};
</script>

<style lang="scss" scoped></style>
