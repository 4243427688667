<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <!-- Filters Start -->
      <div class="col-12 mb-4">
        <div class="card">
          <div class="card-body p-3">
            <div class="row justify-content-center">
              <div class="col-12 col-sm-6 col-md-3">
                <label class="form-label">Fecha Inicio</label>
                <input
                  type="date"
                  class="form-control no-icon"
                  v-model="dateStart"
                  @copy="notCopyPaste"
                  @paste="notCopyPaste"
                  :class="{ 'is-invalid': v$.dateStart.$error }"
                />
                <div v-if="v$.dateStart.$error" class="invalid-feedback">
                  {{ v$.dateStart.$errors[0].$message }}
                </div>
              </div>

              <div class="col-12 col-sm-6 col-md-3">
                <label class="form-label">Fecha Fin</label>
                <input
                  type="date"
                  class="form-control no-icon"
                  v-model="dateEnd"
                  @copy="notCopyPaste"
                  @paste="notCopyPaste"
                  :class="{ 'is-invalid': v$.dateEnd.$error }"
                />
                <div v-if="v$.dateEnd.$error" class="invalid-feedback">
                  {{ v$.dateEnd.$errors[0].$message }}
                </div>
              </div>

              <div class="col-12 col-sm-6 col-md-3">
                <label for="validationCustom05" class="form-label"
                  >No de Identificación</label
                >
                <input
                  type="number"
                  class="form-control"
                  v-model="numberSearch"
                  @input="onlyNumberReg"
                  @change="onlyNumberReg"
                  :class="{ 'is-invalid': v$.numberSearch.$error }"
                />
                <div v-if="v$.numberSearch.$error" class="invalid-feedback">
                  {{ v$.numberSearch.$errors[0].$message }}
                </div>
              </div>
            </div>

            <div class="row justify-content-center">
              <div class="col-12 col-sm-6 col-md-3">
                <button
                  type="button"
                  class="btn btn-success mb-0 w-100"
                  style="margin-top: 30px"
                  @click="search"
                  :disabled="loading"
                >
                  <i v-if="loading" class="fas fa-circle-notch fa-spin"></i>
                  <i v-else class="fas fa-search"></i> Consultar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Filters End -->
    </div>
    <div class="container-fluid" v-if="showData">
      <div class="row my-4">
        <div class="col-12">
          <projects-card
            :data="listRequest"
            :loading="loading"
            :pages="pages"
            :perPages="perPages"
            :filter="filter"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectsCard from './components/ProjectsCard.vue';
import { ref, computed, reactive, onMounted } from 'vue';
import { onlyNumberReg } from '@/utils/functions';
import axiosInstance from '@/services/api';
import Swal from 'sweetalert2';
import { helpers } from '@vuelidate/validators';
import useValidate from '@vuelidate/core';
import { useStore } from 'vuex';

export default {
  components: {
    ProjectsCard,
  },

  setup(props, { emit }) {
    const store = useStore();

    const { getUser } = store.getters;

    const numberSearch = ref(null);
    const dateStart = ref(null);
    const dateEnd = ref(null);

    const filter = reactive({
      numberSearch: null,
      dateStart: null,
      dateEnd: null,
    });

    const loading = ref(false);
    const listRequest = ref([]);
    const pages = ref(1);
    const perPages = ref(10);
    const showData = ref(false);

    const rules = computed(() => {
      return {
        numberSearch: {
          required: helpers.withMessage(
            'Este campo es requerido',
            (value) =>
              ((value == null || value == '') &&
                dateStart.value != null &&
                dateStart.value != '' &&
                dateEnd.value != null &&
                dateEnd.value != '') ||
              (value != null && value != '')
          ),
        },
        dateStart: {
          required: helpers.withMessage(
            'Este campo es requerido',
            (value) =>
              ((value == null || value == '') &&
                (dateEnd.value == null || dateEnd.value == '') &&
                numberSearch.value != null &&
                numberSearch.value != '') ||
              (value != null && value != '')
          ),
        },
        dateEnd: {
          required: helpers.withMessage(
            'Este campo es requerido',
            (value) =>
              ((value == null || value == '') &&
                (dateStart.value == null || dateStart.value == '') &&
                numberSearch.value != null &&
                numberSearch.value != '') ||
              (value != null && value != '')
          ),
          maxMin: helpers.withMessage(
            'La fecha fin no puede ser menor a la inicial',
            (value) => value >= dateStart.value
          ),
        },
      };
    });

    const v$ = useValidate(rules, {
      numberSearch,
      dateStart,
      dateEnd,
    });

    const search = () => {
      v$.value.$touch();

      if (!v$.value.$invalid) {
        loading.value = true;
        showData.value = false;

        if (numberSearch.value == 0) numberSearch.value = null;

        filter.numberSearch = numberSearch.value;
        filter.dateStart = dateStart.value;
        filter.dateEnd = dateEnd.value;

        axiosInstance
          .get(
            `/getListRequests/dateIni/${dateStart.value || '-1'}/dateEnd/${
              dateEnd.value || '-1'
            }/dni/${numberSearch.value || '-1'}/user/${getUser.userId}/rol/${
              getUser.idRol
            }`
          )
          .then(({ data }) => {
            listRequest.value = data.body;
            showData.value = true;
            pages.value = Math.ceil(listRequest.value.length / perPages.value);
          })
          .catch((err) => {
            if (err.response.status === 401) return;

            Swal.fire({
              title: 'Inconveniente en el sistema, intente más tarde',
              icon: 'warning',
              confirmButtonText: 'Aceptar',
            });
          })
          .finally(() => (loading.value = false));
      } else {
        showData.value = false;
      }
    };

    onMounted(() => {
      if (getUser.daysExpiration <= 5 && getUser.showAlertPassword)
        emit('show-modal', true);
    });

    return {
      numberSearch,
      dateStart,
      dateEnd,
      onlyNumberReg,
      loading,
      search,
      listRequest,
      pages,
      perPages,
      showData,
      v$,
      filter,
    };
  },
};
</script>

<style scoped>
.no-icon {
  background-image: none !important;
  padding-right: inherit !important;
}
</style>
