<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <!-- Filters Start -->
      <div class="col-12 mb-4">
        <div class="card">
          <div class="card-body p-3">
            <div class="row justify-content-center">
              <div class="col-12 col-sm-6 col-md-3">
                <label class="form-label">Fecha Inicio</label>
                <input
                  type="date"
                  class="form-control no-icon"
                  v-model="dateStart"
                  @copy="notCopyPaste"
                  @paste="notCopyPaste"
                  :class="{ 'is-invalid': v$.dateStart.$error }"
                />
                <div v-if="v$.dateStart.$error" class="invalid-feedback">
                  {{ v$.dateStart.$errors[0].$message }}
                </div>
              </div>

              <div class="col-12 col-sm-6 col-md-3">
                <label class="form-label">Fecha Fin</label>
                <input
                  type="date"
                  class="form-control no-icon"
                  v-model="dateEnd"
                  @copy="notCopyPaste"
                  @paste="notCopyPaste"
                  :class="{ 'is-invalid': v$.dateEnd.$error }"
                />
                <div v-if="v$.dateEnd.$error" class="invalid-feedback">
                  {{ v$.dateEnd.$errors[0].$message }}
                </div>
              </div>

              <div class="col-12 col-sm-4" v-if="getUser.idRol == 1">
                <div class="form-group">
                  <label for="txtBanco">Banco</label>
                  <select
                    class="form-control"
                    id="txtBanco"
                    v-model="bankId"
                    :class="{ 'is-invalid': v$.currentBankId.$error }"
                  >
                    <option
                      v-for="item of listBank"
                      :key="item.key"
                      :value="item.key"
                    >
                      {{ item.value }}
                    </option>
                  </select>
                  <div v-if="v$.currentBankId.$error" class="invalid-feedback">
                    {{ v$.currentBankId.$errors[0].$message }}
                  </div>
                </div>
              </div>
            </div>

            <div class="row justify-content-center">
              <div class="col-12 col-sm-6 col-md-3">
                <button
                  type="button"
                  class="btn btn-success mb-0 w-100"
                  style="margin-top: 30px"
                  @click="search"
                  :disabled="loading"
                >
                  <i v-if="loading" class="fas fa-circle-notch fa-spin"></i>
                  <i v-else class="fas fa-filter"></i> Consultar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Filters End -->
    </div>
    <div class="container-fluid" v-if="showData">
      <div class="row my-4">
        <div class="col-12">
          <logs-card
            :data="listRequest"
            :loading="loading"
            :pages="pages"
            :perPages="perPages"
            :filter="filter"
            :totalQuantity="totalQuantity"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LogsCard from './components/LogsCard.vue';
import { ref, computed, reactive, onMounted } from 'vue';
import { onlyNumberReg } from '@/utils/functions';
import axiosInstance from '@/services/api';
import Swal from 'sweetalert2';
import { helpers, required } from '@vuelidate/validators';
import useValidate from '@vuelidate/core';
import { useStore } from 'vuex';

export default {
  components: {
    LogsCard,
  },

  setup(props, { emit }) {
    const store = useStore();

    const { getUser } = store.getters;

    const dateStart = ref(null);
    const dateEnd = ref(null);
    const bankId = ref(-1);
    const listBank = ref([{ key: -1, value: 'Seleccione un banco' }]);

    const filter = reactive({
      dateStart: null,
      dateEnd: null,
      bankId: null,
    });

    const loading = ref(false);
    const listRequest = ref([]);
    const pages = ref(1);
    const perPages = ref(10);
    const showData = ref(false);

    const currentBankId = computed(() =>
      bankId.value == -1 ? null : bankId.value
    );

    const isRoot = (value) => {
      return getUser.idRol != 1 || (getUser.idRol == 1 && value != null);
    };

    const rules = computed(() => {
      return {
        dateStart: {
          required: helpers.withMessage('Este campo es requerido', required),
        },
        dateEnd: {
          required: helpers.withMessage('Este campo es requerido', required),
          maxMin: helpers.withMessage(
            'La fecha fin no puede ser menor a la inicial',
            (value) => value >= dateStart.value
          ),
        },
        currentBankId: {
          required: helpers.withMessage('Este campo es requerido', isRoot),
        },
      };
    });

    const v$ = useValidate(rules, {
      dateStart,
      dateEnd,
      currentBankId,
    });

    const totalQuantity = computed(() =>
      listRequest.value.reduce((acc, cur) => acc + cur.quantity, 0)
    );

    axiosInstance.get(`/banks`).then(({ data }) => {
      const { body = [] } = data;

      body.shift();

      listBank.value = [
        { key: -1, value: 'Seleccione un banco' },
        ...body.map((i) => ({ key: i.id, value: i.name })),
      ];
    });

    const search = () => {
      v$.value.$touch();

      if (!v$.value.$invalid) {
        loading.value = true;
        showData.value = false;

        const payloadBanckId =
          getUser.idRol == 1 ? bankId.value : getUser.idEntidad;

        filter.dateStart = dateStart.value;
        filter.dateEnd = dateEnd.value;
        filter.bankId = payloadBanckId;

        axiosInstance
          .get(
            `/click-counter/bank/${payloadBanckId}/dateIni/${dateStart.value}/dateEnd/${dateEnd.value}/export/0`
          )
          .then(({ data }) => {
            listRequest.value = data.body;
            showData.value = true;
            pages.value = Math.ceil(listRequest.value.length / perPages.value);
          })
          .catch((err) => {
            if (err.response.status === 401) return;

            Swal.fire({
              title: 'Inconveniente en el sistema, intente más tarde',
              icon: 'warning',
              confirmButtonText: 'Aceptar',
            });
          })
          .finally(() => (loading.value = false));
      } else {
        showData.value = false;
      }
    };

    onMounted(() => {
      if (getUser.daysExpiration <= 5 && getUser.showAlertPassword)
        emit('show-modal', true);
    });

    return {
      dateStart,
      dateEnd,
      onlyNumberReg,
      loading,
      search,
      listRequest,
      pages,
      perPages,
      showData,
      v$,
      filter,
      totalQuantity,
      bankId,
      listBank,
      getUser,
    };
  },
};
</script>

<style scoped>
.no-icon {
  background-image: none !important;
  padding-right: inherit !important;
}
</style>
