<template>
  <main class="mt-0 main-content main-content-bg">
    <section>
      <div class="page-header min-vh-75">
        <div class="container">
          <div class="row">
            <div class="mx-auto col-xl-4 col-lg-5 col-md-6 d-flex flex-column">
              <div class="mt-8 card card-plain">
                <div class="pb-0 card-header text-start">
                  <h3 class="font-weight-bolder text-success text-gradient">
                    Bienvenido de nuevo
                  </h3>
                  <p class="mb-0">
                    Ingrese su usuario y contraseña para iniciar sesión
                  </p>
                </div>
                <div class="card-body">
                  <form class="text-start">
                    <soft-input
                      id="username"
                      type="text"
                      placeholder="Usuario"
                      :value="client.username"
                      @input="setUsername"
                      label="Usuario"
                      :error="v$.username.$error"
                      :errors="v$.username.$errors"
                      :disabled="loading"
                      @keydown.enter="login"
                    />

                    <soft-input
                      id="password"
                      type="password"
                      placeholder="Contraseña"
                      :value="client.password"
                      @input="setPassword"
                      label="Contraseña"
                      :error="v$.password.$error"
                      :errors="v$.password.$errors"
                      :disabled="loading"
                      @keydown.enter="login"
                    />

                    <div class="text-center">
                      <soft-button
                        class="my-4 mb-2"
                        variant="gradient"
                        color="success"
                        full-width
                        type="button"
                        @click="login"
                        :disabled="loading"
                      >
                        <i
                          v-if="loading"
                          class="fas fa-circle-notch fa-spin"
                        ></i>
                        Iniciar Sesión
                      </soft-button>
                    </div>
                  </form>
                </div>
                <div class="px-1 pt-0 text-center card-footer px-lg-2">
                  <p class="mx-auto mb-4 text-sm">
                    ¿Olvidaste la contraseña?
                    <router-link
                      :to="{ name: 'reset-password' }"
                      class="text-success text-gradient font-weight-bold"
                      >Ingresa aquí</router-link
                    >
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div
                class="top-0 oblique position-absolute h-100 d-md-block d-none me-n8"
              >
                <div
                  class="bg-cover oblique-image position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6"
                  :style="{
                    backgroundImage:
                      'url(' +
                      require('@/assets/img/curved-images/curved9.jpg') +
                      ')',
                  }"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
  <app-footer />
</template>

<script>
import AppFooter from '@/examples/PageLayout/Footer.vue';
import SoftInput from '@/components/SoftInput.vue';
import SoftButton from '@/components/SoftButton.vue';
const body = document.getElementsByTagName('body')[0];
import { mapMutations } from 'vuex';
import { reactive, computed, ref } from 'vue';
import Swal from 'sweetalert2';
import { required, helpers } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import axiosInstance from '@/services/api';
import TokenService from '@/services/token.service';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

export default {
  name: 'SignIn',

  components: {
    AppFooter,
    SoftInput,
    SoftButton,
  },

  setup() {
    const router = useRouter();
    const store = useStore();

    if (TokenService.getLocalAccessToken()) {
      const menuDefault = TokenService.getUser()?.menus.filter(
        (menu) => menu.default == 1
      );

      router.replace({
        name: menuDefault[0].nameRouter,
      });
    }

    const clientInitial = reactive({
      username: '',
      password: '',
    });

    const loading = ref(false);

    const client = reactive({});

    Object.assign(client, clientInitial);

    client.username = '';
    client.password = '';

    const rules = computed(() => ({
      username: {
        required: helpers.withMessage('Usuario es requerido', required),
      },
      password: {
        required: helpers.withMessage('Contraseña es requerido', required),
      },
    }));

    const v$ = useVuelidate(rules, client);

    const setUsername = (event) => {
      client.username = event.target.value;
    };

    const setPassword = (event) => {
      client.password = event.target.value;
    };

    const login = async () => {
      v$.value.$touch();

      if (!v$.value.$invalid) {
        loading.value = true;

        const payload = {
          username: client.username,
          password: client.password,
        };

        await axiosInstance
          .post(`/auth/signinWeb`, payload)
          .then(({ data }) => {
            const {
              accessToken,
              refreshToken,
              menus,
              userId,
              idRol,
              idEntidad,
              fullname,
              nameRol,
              daysExpiration,
              bloqueo,
            } = data.body;

            if (bloqueo) {
              Swal.fire({
                title: 'Usuario bloqueado por múltiples intentos fallidos.',
                text:
                  'Para recuperar el acceso, por favor continúa y realiza el cambio de contraseña.',
                icon: 'warning',
                confirmButtonText: 'Continuar',
              }).then(() => {
                router.push({ name: 'reset-password' });
              });

              return;
            }

            if (daysExpiration <= 0) {
              Swal.fire({
                title: '¡Notificación!',
                text:
                  'Te informamos que la contraseña ha caducado. Para garantizar el acceso seguro a tu cuenta, te invitamos a continuar con el proceso de actualización de tu contraseña.',
                icon: 'warning',
                confirmButtonText: 'Continuar',
              }).then(() => {
                router.push({ name: 'reset-password' });
              });

              return;
            }

            if (menus.length > 0) {
              store.dispatch('initState');
              store.dispatch('setMenus', menus);
              store.dispatch('setInitState', {
                userId,
                idRol,
                idEntidad,
                fullname,
                nameRol,
                daysExpiration,
                showAlertPassword: true,
              });

              const user = {
                menus,
                accessToken,
                refreshToken,
                userId,
                idRol,
                idEntidad,
                fullname,
                nameRol,
                daysExpiration,
                showAlertPassword: true,
              };

              TokenService.setUser(user);

              menus.forEach((menu) => {
                if (menu.default == 1) {
                  router.push({ name: menu.nameRouter });
                }
              });
            } else {
              Swal.fire({
                text: `Inconvenientes en el sistema, inténtelo más tarde`,
                icon: 'error',
                confirmButtonText: 'Aceptar',
              });
            }
          })
          .catch((err) => {
            console.log(`===>`, err);
            const { message } = err?.response?.data?.error || {};
            Swal.fire({
              text:
                message !== null &&
                message !== '' &&
                typeof message !== 'undefined'
                  ? message
                  : `Credenciales Incorrectas`,
              icon: 'error',
              confirmButtonText: 'Aceptar',
            });
          })
          .finally(() => (loading.value = false));
      }
    };

    return {
      client,
      v$,
      setUsername,
      setPassword,
      login,
      loading,
    };
  },

  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove('bg-gray-100');
  },

  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add('bg-gray-100');
  },

  methods: {
    ...mapMutations(['toggleEveryDisplay', 'toggleHideConfig']),
  },
};
</script>
