<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body p-3">
            <div class="container-fluid">
              <div class="row justify-content-center">
                <div class="col-12 col-sm-6 col-md-3">
                  <label class="form-label">Fecha Inicio</label>
                  <input
                    type="date"
                    class="form-control no-icon"
                    v-model="dateStart"
                    @copy="notCopyPaste"
                    @paste="notCopyPaste"
                    :class="{ 'is-invalid': v$.dateStart.$error }"
                  />
                  <div v-if="v$.dateStart.$error" class="invalid-feedback">
                    {{ v$.dateStart.$errors[0].$message }}
                  </div>
                </div>

                <div class="col-12 col-sm-6 col-md-3">
                  <label class="form-label">Fecha Fin</label>
                  <input
                    type="date"
                    class="form-control no-icon"
                    v-model="dateEnd"
                    @copy="notCopyPaste"
                    @paste="notCopyPaste"
                    :class="{ 'is-invalid': v$.dateEnd.$error }"
                  />
                  <div v-if="v$.dateEnd.$error" class="invalid-feedback">
                    {{ v$.dateEnd.$errors[0].$message }}
                  </div>
                </div>

                <div class="col-12 col-sm-6 col-md-3">
                  <button
                    type="button"
                    class="btn btn-success mb-0 w-75"
                    style="margin-top: 30px"
                    @click="search"
                    :disabled="loading"
                  >
                    <i v-if="loading" class="fas fa-circle-notch fa-spin"></i>
                    <i v-else class="fas fa-search"></i> Consultar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="loading" class="row">
        <div class="col-12 text-center">
          <span class="fas fa-circle-notch fa-spin fa-2x mb-4"></span>
        </div>
      </div>

      <div v-if="showData" class="col-12 mt-5">
        <div class="card z-index-2">
          <BarChart
            id="barChartRequest"
            title="Solicitudes por Fechas"
            :chart="chartDate"
            titleY="No. de Solicitudes"
            titleX="Fechas"
          ></BarChart>
        </div>
      </div>

      <div v-if="showData" class="col-12 mt-5">
        <div class="card z-index-2">
          <BarChart
            id="barChartAsesor"
            :height="600"
            title="Solicitudes por Asesores"
            :chart="chartAsesor"
            titleY="Asesores"
            titleX="No. de Solicitudes"
            :plotOptions="{
              bar: {
                borderRadius: 4,
                horizontal: true,
              },
            }"
          ></BarChart>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BarChart from '@/examples/Charts/BarChart.vue';
import { reactive, ref, computed, onMounted } from 'vue';
import { notCopyPaste } from '@/utils/functions';
import { required, helpers } from '@vuelidate/validators';
import useValidate from '@vuelidate/core';
import axiosInstance from '@/services/api';
import Swal from 'sweetalert2';
import { useStore } from 'vuex';

export default {
  components: {
    BarChart,
  },

  setup(props, { emit }) {
    const store = useStore();

    const { getUser } = store.getters;

    const dateStart = ref(null);
    const dateEnd = ref(null);
    const loading = ref(false);
    const showData = ref(false);

    const chartDate = reactive({
      labels: [],
      datasets: [
        {
          label: 'No. de Solicitudes',
          data: [],
          backgroundColor: ['rgba(214, 64, 69, 0.2)'],
          borderColor: ['rgba(214, 64, 69, 1)'],
          borderWidth: 1,
        },
      ],
    });

    const chartAsesor = reactive({
      labels: [],
      datasets: [
        {
          label: 'No. de Solicitudes',
          data: [],
          backgroundColor: ['rgba(214, 64, 69, 0.2)'],
          borderColor: ['rgba(214, 64, 69, 1)'],
          borderWidth: 1,
        },
      ],
    });

    const rules = computed(() => {
      return {
        dateStart: {
          required: helpers.withMessage('Este campo es requerido', required),
        },
        dateEnd: {
          required: helpers.withMessage('Este campo es requerido', required),
          maxMin: helpers.withMessage(
            'La fecha fin no puede ser menor a la inicial',
            (value) => value >= dateStart.value
          ),
        },
      };
    });

    const v$ = useValidate(rules, {
      dateStart,
      dateEnd,
    });

    const search = () => {
      v$.value.$touch();

      if (!v$.value.$invalid) {
        loading.value = true;
        showData.value = false;

        axiosInstance
          .get(
            `getDataGraph/dateIni/${dateStart.value}/dateEnd/${dateEnd.value}/user/${getUser.userId}/rol/${getUser.idRol}`
          )
          .then(({ data }) => {
            const { body } = data;

            if (body[0].labels != null) {
              chartDate.labels = JSON.parse(body[0].labels).labels;
              chartDate.datasets[0].data = JSON.parse(
                body[0].cantidades
              ).cantidades;
            } else {
              chartDate.labels = [];
              chartDate.datasets[0].data = [];
            }

            if (body[1].labels != null) {
              chartAsesor.labels = JSON.parse(body[1].labels).labels;
              chartAsesor.datasets[0].data = JSON.parse(
                body[1].cantidades
              ).cantidades;
            } else {
              chartAsesor.labels = [];
              chartAsesor.datasets[0].data = [];
            }

            showData.value = true;
          })
          .catch((err) => {
            if (err.response.status === 401) return;

            Swal.fire({
              title: 'Inconveniente en el sistema, intente más tarde',
              icon: 'warning',
              confirmButtonText: 'Aceptar',
            });
          })
          .finally(() => (loading.value = false));
      } else {
        showData.value = false;
      }
    };

    onMounted(() => {
      if (getUser.daysExpiration <= 5 && getUser.showAlertPassword)
        emit('show-modal', true);
    });

    return {
      chartDate,
      chartAsesor,
      notCopyPaste,
      dateStart,
      dateEnd,
      loading,
      v$,
      search,
      showData,
    };
  },
};
</script>

<style scoped>
.no-icon {
  background-image: none !important;
  padding-right: inherit !important;
}
</style>
